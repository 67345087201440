import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

const client = new ApolloClient({
    uri: 'https://api.designrealitytech.com/graphql',
    cache: new InMemoryCache()
  });


ReactDOM.render(
  <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <ScrollToTop />
                <App />
            </BrowserRouter>
        </ApolloProvider>    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
