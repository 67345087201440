import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Switch, Redirect} from 'react-router-dom';
import Home from './Components/Home/Home';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import NavigationDrawer from './Components/NavigationDrawer/NavigationDrawer';
import PrivacyPolicy from './Components/PrivacyAndTerms/PrivacyPolicy';
import Terms from './Components/PrivacyAndTerms/Terms';
import TransitionPage from './Components/UI/TransitionPage/TransitionPage';
import InnerPage from './Components/InnerPage/InnerPage';
import { useQuery, gql } from '@apollo/client';

const PAGE_QUERY = gql`
    query PageQuery{
        pages{
        url,
        pagename
        }
    }`;

function App() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [pages, setPages] = useState([]);
    const {data} = useQuery(PAGE_QUERY);


    useEffect(() => {
        if(data){
            const pageArr = [];
            data.pages.forEach((item) => {
                pageArr.push({name: item.pagename, url: item.url});
            });
            setPages(pageArr);
        }
    },[data]);

    if(pages.length){
        return (
            <div className="App">
                <Header open={drawerOpen} setOpen={setDrawerOpen} pages={pages}/>
                <NavigationDrawer open={drawerOpen} setOpen={setDrawerOpen} pages={pages}/>
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    {pages.length&&pages.map((item) =>
                        <Route path={item.url}>
                            <InnerPage url={item.url}/>
                        </Route>
                    )}
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                    </Route>
                    <Redirect to="/"/>
                </Switch>
                <Footer pages={pages}/>
            </div>
      );
    }else return <TransitionPage />
}

export default App;
