import React from 'react';
import './NavigationDrawer.css';
import {Drawer} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Cross from '../../Assets/Icons/white_cross.svg';

const useStyles = makeStyles({
    PaperRoot: {
        width: "20rem",
        maxWidth: "100vw",
        padding: "3rem 2rem",
        backgroundColor: "#266DA8"
    }
});

export default function NavigationDrawer({open, setOpen, pages}){
    const classes = useStyles();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setOpen(open)
    };
    return(
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)} classes={{paper: classes.PaperRoot}}>
            <div className="nav">
                <div className="nav_section_1">
                    <img src={Cross} alt="cross" onClick={() => setOpen(false)}/>
                </div>
                <div className="nav_section_2">
                    <Link to="/" onClick={() => setOpen(false)}>Home</Link>
                    <Link to="/about" onClick={() => setOpen(false)}>About</Link>
                    {pages.map((item) => 
                        <Link to={item.url} onClick={() => setOpen(false)}>{item.name}</Link>
                    )}
                    <Link to="/contact" onClick={() => setOpen(false)}>Contact</Link>
                </div>
                <div className="nav_section_3">
                    <Link to="/privacy-policy" onClick={() => setOpen(false)}>PRIVACY POLICY</Link>
                    <Link to="/terms" onClick={() => setOpen(false)}>TERMS OF SERVICE</Link>
                </div>
            </div>
        </Drawer>
    )
}