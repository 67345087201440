import React, {useState, useEffect} from 'react';
import './Header.css';
import DRLogo from '../../Assets/drlogo.png'
import {NavLink, Link} from 'react-router-dom'
import Hamburger from '../../Assets/Icons/hamburger_icon.svg';

export default function Header(props) {
    const [atTop, setAtTop] = useState(true); // boolean denoting whether viewport is at the top of the screen
    const [scrollDir, setScrollDir] = useState(0); // +ve when scrolling down, 0 when scrolling up


    // Adds an event listener for all header animations
    useEffect(() => {        
        setAtTop((window.scrollY === 0));

        let position = 0;
        let previousPosiion = 0;
        let threshold = 80;
        let ticking = false;
        
        // Sets atTop to true when window.scrollY is under 20px
        const scrollTopHandler = (position) => {            
            if(position < threshold){
                setAtTop(true);
            }else if(position >= threshold){
                setAtTop(false);
            }
        };

        // Sets scrollDir to positive value if scrolling down
        const scrollDifferenceHandler = (previousPos, currentPos) => {
            if(previousPos < currentPos){
                setScrollDir(1);
            } else setScrollDir(0)
        }

        // Throttled callback for scroll event listener
        const callback = (event) => {
            previousPosiion = position;
            position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    scrollTopHandler(position);
                    scrollDifferenceHandler(previousPosiion, position);
                    ticking = false;
                });

                ticking = true;
            }
        } 

        document.addEventListener('scroll', callback);

        return(() => window.removeEventListener('scroll', callback));
    },[]);

    return(
        <div className={"header" + (atTop? " flat" :  "") + ((scrollDir > 0) ? ' invisible' : '')}>
            <Link className="logo_link" to="/">
                <img src={DRLogo} alt="" />
            </Link>
            <NavLink className="header_link" to="/about" activeClassName="header_link_active">About</NavLink>
            {props.pages.map((item) => 
                <NavLink className="header_link" to={item.url} activeClassName="header_link_active">{item.name}</NavLink>
            )}
            <NavLink className="header_link" to="/contact" activeClassName="header_link_active">Contact</NavLink> 
            <button className="nav_menu_button" onClick={() => {
                if(!props.open){
                    props.setOpen(true);
                }
            }}>
                <img src={Hamburger} alt="Navigation menu button"/>
            </button>
        </div>
    )
}