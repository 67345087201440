import React, { useEffect, useRef, useState } from 'react';
import './StatCount.css';
import { animated, useSpring } from 'react-spring';

export default function StatCount(props){
    const [animate, setAnimate] = useState(false); //Begins animation when true
    const element = useRef(null);

    const {number} = useSpring({number: animate? props.value : 0, config: { mass: 1, tension: 280, friction: 120 }});

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const callback = (entries) => {
            if(!animate && entries[0].isIntersecting){
                setAnimate(true);
            }            
        }
            
        const observer = new IntersectionObserver(callback, options);
        observer.observe(element.current)
    }, []);

    return(
        <div className="stat_item" ref={element}>
            <animated.p className="number">{number.to(n => n.toFixed(0) + (props.isPercentage? "%" : ""))}</animated.p>
            <p className="description">{props.title}</p>
        </div>
    )
}