import React from 'react';

export default function TransitionPage(){
    return(
        <div style={{width: "100vw", 
        height: "100vh", 
        position: "fixed", 
        top: "0", 
        left: "0", 
        zIndex: "11", 
        backgroundColor: "white"}}/>
    )
}