import React from 'react';
import './CustomAccordion.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import {ReactComponent as Plus} from '../../../Assets/Icons/plus.svg';

const useStyles = makeStyles({
    PaperRoot: {
        backgroundColor: "#266DA8",
        marginTop: 0,
        marginBottom: '2rem',
        '&::before':{
            height: 0
        },
        '&.Mui-expanded': {
            marginTop: 0
        }
    },
    SummaryRoot: {
        padding: 0,
        minHeight: '3rem',
        '&.Mui-expanded': {
            minHeight: '3rem'
        }
    },
    SummaryExpanded:{
        '&.MuiAccordionSummary-content': {
            margin: 0,
            padding: 0
        }
    },
    SummaryContent: {
        margin: 0,
        padding: 0
    },
    DetailsRoot: {
        padding: '1rem 0'
    }
})

export default function CustomAccordion(props){
    const classes = useStyles();
    return(
        <Accordion square classes={{root: classes.PaperRoot}} elevation={0}>
            <AccordionSummary
            expandIcon={<Plus />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{root: classes.SummaryRoot, expanded: classes.SummaryExpanded, content: classes.SummaryContent}}
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails classes={{root: classes.DetailsRoot}}>{props.text}</AccordionDetails>
        </Accordion>
    )
}