import React, {useState} from 'react';
import './Contact.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import {Snackbar, CircularProgress} from '@material-ui/core';
import {useMutation, useQuery, gql} from '@apollo/client';
import TransitionPage from '../UI/TransitionPage/TransitionPage';

const ADD_EMAIL = gql`
    mutation AddEmail($name: String!, $company: String!, $email: String!,
        $phoneNumber: String, $query: String){
        createEmail(input: {data: {name: $name, 
            companyName: $company, 
            email: $email, 
            phoneNumber: $phoneNumber, 
            query: $query}}){
            email{
                name
            }
        }
    }`;

const CONTENT_QUERY = gql`
    query ContactPageContent{
        contactPage{
            title1,
            title2,
            address{
                name,
                number,
                address1,
                address2
            }
        }
    }`;


const useStyles = makeStyles(({
    FormControlRoot: {
        marginBottom: "2rem",
        maxWidth: "30rem",
    },
    InputBaseRoot: {
        color: 'white',
        '&.MuiInput-underline:after': {
            borderBottom: "1px solid white"
        },
        '&.MuiInput-underline:before': {
            borderBottom: "1px solid #FFFFFF44"
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: "1px solid #FFFFFF88"
        },
    },
    Input: {
        paddingBottom: "0.6rem",
        '&::placeholder': {
            color: 'white',
            opacity: "0.9",
            '&::after': {
                content: "(Optional)"
            }
        },
    },
    SnackbarContentRoot: {
        backgroundColor: "white",
        border: "1px solid #266DA8",
        color: "#266DA8",
        "&.MuiPaper-elevation6": {
            boxShadow: "none"
        }
    },
    CircularProgressColorPrimary: {        
        color: 'white'
    }
}));

export default function Contact(){
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [query, setQuery] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    const classes = useStyles();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackarMmessage] = useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };


    const [addEmail] = useMutation(ADD_EMAIL);
    const {data} = useQuery(CONTENT_QUERY);

    const submitButtonHandler = () => {
        setSubmitLoading(true);
        if(name === ""){
            setSnackarMmessage("Name field is required");
            setSnackbarOpen(true);
            setSubmitLoading(false);
        }else if(company === ""){
            setSnackarMmessage("Company name field is required");
            setSnackbarOpen(true);
            setSubmitLoading(false);
        }else if(email === ""){
            setSnackarMmessage("Email field is required");
            setSnackbarOpen(true);
            setSubmitLoading(false);
        }else {
            addEmail({variables: {
                name,
                company,
                email,
                phoneNumber,
                query
            }}).then(res => {
                setSnackarMmessage("Thank you for contacting us, we will get back to you shortly");
                setName("");
                setCompany("");
                setEmail("");
                setPhoneNumber("");
                setQuery("");
                setSnackbarOpen(true);
                setSubmitLoading(false);              
            }).catch((err) => {
                if(err.message === "ValidationError"){
                    setSnackarMmessage("Please type in a valid email address");
                    setSnackbarOpen(true);
                }else {
                    setSnackarMmessage("Something went wrong");
                    setSnackbarOpen(true);
                }
                setSubmitLoading(false);
            });
        }
    };


    if(data && data.contactPage){
        return(
            <div className="contact">
                <div className="contact_section_1">
                    <h2>{data.contactPage.title1}</h2>
                    <h3>{data.contactPage.title2}</h3>
                </div>
    
                <div className="contact_section_2">
                    <div className="info">
                        {data.contactPage.address.map((item) => 
                            <div className="info_item">
                                <h4>{item.name}</h4>
                                <h5>{item.number}</h5>
                                <p>{item.address1}</p>
                                <p>{item.address2}</p>
                            </div>
                        )}                        
                    </div>
                    <div className="form">
                        <TextField
                            classes={{root: classes.FormControlRoot}}
                            InputProps={{classes: {root: classes.InputBaseRoot, input: classes.Input}}}
                            placeholder="Name"
                            fullWidth  
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                    submitButtonHandler()
                                }
                            }}
                        />
                        <TextField     
                            classes={{root: classes.FormControlRoot}}
                            InputProps={{classes: {root: classes.InputBaseRoot, input: classes.Input}}}               
                            placeholder="Company Name"
                            fullWidth  
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                    submitButtonHandler()
                                }
                            }}
                        />
                        <TextField     
                            classes={{root: classes.FormControlRoot}}
                            InputProps={{classes: {root: classes.InputBaseRoot, input: classes.Input}}}               
                            placeholder="Email"
                            fullWidth  
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                    submitButtonHandler()
                                }
                            }}
                        />
                        <TextField       
                            classes={{root: classes.FormControlRoot}}
                            InputProps={{classes: {root: classes.InputBaseRoot, input: classes.Input}}}             
                            placeholder="Phone Number (Optional)"
                            fullWidth  
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                    submitButtonHandler()
                                }
                            }}
                        />                    
                        <TextField
                            classes={{root: classes.FormControlRoot}}
                            InputProps={{classes: {root: classes.InputBaseRoot, input: classes.Input}}}                
                            placeholder="Query (Optional)"
                            fullWidth  
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                    submitButtonHandler()
                                }
                            }}
                        />
                        <div className="submit">
                            <button onClick={submitButtonHandler} 
                            disabled={submitLoading} 
                            className={submitLoading? "loading" : ""}>
                                {
                                    submitLoading? 
                                    <CircularProgress classes={{colorPrimary: classes.CircularProgressColorPrimary}}
                                        size="2em"
                                    /> :
                                     "Send Request"
                                }
                            </button>
                            <p>By clicking the send request button you agree to our <Link to="privacy-policy">privacy policy</Link> terms</p>
                        </div>
                    </div>                
                </div>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={snackbarMessage}
                    ContentProps={{classes: {root: classes.SnackbarContentRoot}}}
                />
            </div>
        )
    } else return <TransitionPage />
}