import React from 'react';
import './PrivacyAndTerms.css';
import {useQuery, gql} from '@apollo/client';
import TransitionPage from '../UI/TransitionPage/TransitionPage';
import ReactMarkdown from 'react-markdown'
import rehype from 'rehype-raw';
import gfm from 'remark-gfm';

const TERMS_QUERY = gql`
    query TermsQuery{
        terms{
            title,
            text
        }
    }`;

export default function Terms(){
    const {data} = useQuery(TERMS_QUERY);

    if(data && data.terms){
        return(
            <div className="privacy_and_terms">
                <h1>Terms of Service</h1>
                {data.terms.map((item) => {
                    return <>
                        <h3>{item.title}</h3>
                        <ReactMarkdown skipHtml remarkPlugins={[gfm]} rehypePlugins={[rehype]}>{item.text}</ReactMarkdown>
                    </>
                })}
            </div>
        )
    }else return <TransitionPage />
    
}