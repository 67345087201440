import React, {useState, useRef, useEffect} from 'react';
import './Testimonials.css';
import {animated, useTransition} from 'react-spring';
import {ReactComponent as Arrow} from '../../../Assets/Icons/solid-arrow.svg';


// Function that returns previous testimonial index
const usePrevTestimonial = index => {
    const ref = useRef(0);
    useEffect(() => {
        ref.current = index;
    });
    return ref.current;
};

export default function Testimonials(props){
    const [ready, setReady] = useState(true); //whether component is ready for animation, true when previous animation is at rest
    // testimonial indices
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const prevTestimonial = usePrevTestimonial(currentTestimonial);

    //Trasitions for the main testimonial text
    const testimonialTransitions = useTransition(currentTestimonial, {
        from: () => {
            if (currentTestimonial > prevTestimonial) {
                return {opacity: 0, transform: 'translate3d(120%, -50% ,0)'}
            } else return {opacity: 0, transform: 'translate3d(-120%, -50%, 0)'}
        },
        enter: { opacity: 1, transform: 'translate3d(0, -50%, 0)', delay: 150},
        leave: () => {
            if (currentTestimonial > prevTestimonial) {
                return {opacity: 0, transform: 'translate3d(-50%, -50%, 0)'}
            } else return {opacity: 0, transform: 'translate3d(50%, -50%, 0)'}
        },
        // duration: 250,
        onRest: () => {
            if(!ready){
                setReady(true)
            }
        }, 
    });

    //Trasitions for the extra testimonial text i.e. name, org etc
    const extraTransitions = useTransition(currentTestimonial, {
        from: () => {
                return {opacity: 0}
                },
        enter: { opacity: 1, delay: 750},
        leave: () => {
                return {opacity: 0}
            },
        config: {
            duration: 250
        }
    });

    return(
        <div className="testimonials">
            <h2>{props.title}</h2>
            <div className="buttons">
                <button className="arrow-left" 
                    disabled={currentTestimonial === 0} 
                    onClick={() => {
                        if(ready){
                            setReady(false);
                            setCurrentTestimonial((x) => x - 1)
                        }                        
                    }}>
                    <Arrow />
                </button>
                <button className="arrow-right" 
                disabled={currentTestimonial === props.data.length - 1} 
                onClick={() => {
                    if(ready){
                        setReady(false);
                        setCurrentTestimonial((x) => x + 1)
                    }                        
                }}>
                    <Arrow />
                </button>
                <div className="divider_line" />
            </div>
            <div className="testimonial_item">                
                <div className="main">
                    {testimonialTransitions((style, item) => {
                        return(
                            <animated.p style={{...style}}>{props.data[item].mainText}</animated.p>
                        )
                    })}
                </div>
                <div className="extra">
                    {extraTransitions((style, item) => {
                        return(
                            <animated.div className="extra_content" style={{opacity: style.opacity}} >
                                <p className="name">{props.data[item].name}</p>
                                <p className="position">{props.data[item].position}</p>
                                <p className="org">{props.data[item].organization}</p>
                            </animated.div>     
                        )
                    })}                                                      
                </div>
            </div>
        </div>
    )
}