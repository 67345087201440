import React from 'react';
import './Partner.css';
import CONSTANTS from '../../../Constants';

export default function Partner(props){
    let dimension;

    const assignDimension = (itemsPerRow) => {
        if(itemsPerRow === 4){
            dimension = "22vw";
        }else if(itemsPerRow === 3){
            dimension = "27vw";
        }else if(itemsPerRow === 2){
            dimension = "35vw";
        }else{
            dimension = "52vw"
        }
    }

    if(props.count > 4){
        if(props.count % 4 === 0){
            assignDimension(4);
        }else if(props.count % 3 === 0){
            assignDimension(3);
        } else if(props.count % 4 === 3){
            assignDimension(4);
        } else if(props.count % 3 === 2){
            assignDimension(3);
        } else if(props.count % 4 === 2){
            assignDimension(4);
        }else if(props.count%3 === 1){
            assignDimension(3);
        }
    }else {
        assignDimension(props.count);
    }

    return(
        <div className="partner" style={{width: dimension, height: dimension}}>
            <p className="title">{props.title}</p>
            <img src={CONSTANTS.baseUrl + props.img.url} alt={props.img.alternativeText}/>
        </div>
    )
}