import React from 'react';
import './PrivacyAndTerms.css';
import {useQuery, gql} from '@apollo/client';
import TransitionPage from '../UI/TransitionPage/TransitionPage';
import ReactMarkdown from 'react-markdown'
import rehype from 'rehype-raw';
import gfm from 'remark-gfm';

const POLICY_QUERY = gql`
    query PolicyQuery{
        privacyPolicies{
            title,
            text
        }
    }`;

export default function PrivacyPolicy(){
    const {data} = useQuery(POLICY_QUERY);
    
    if(data && data.privacyPolicies){
        return(
            <div className="privacy_and_terms">
                <h1>Privacy Policy</h1>
                {data.privacyPolicies.map((item) => {
                    return <>
                        <h3>{item.title}</h3>
                        <ReactMarkdown skipHtml remarkPlugins={[gfm]} rehypePlugins={[rehype]}>{item.text}</ReactMarkdown>
                    </>
                })} 
            </div>
        )
    }else return <TransitionPage />
}