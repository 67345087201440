import React, {useEffect, useState} from 'react';
import './InnerPage.css';
import {Link} from 'react-router-dom';
import CustomAccordion from '../UI/CustomAccordion/CustomAccordion';
import Partner from '../UI/Partner/Partner';
import Testimonials from '../UI/Testimonials/Testimonials';
import StatCount from '../UI/StatCount/StatCount';
import TransitionPage from '../UI/TransitionPage/TransitionPage';
import {useQuery, gql} from '@apollo/client';
import CONSTANTS from '../../Constants';

const CONTENT_QUERY = gql`
query GetPageContent($url: String!){
    pages(where: {url: $url}){
      innerPageLandingObject{
        title,
        text,
        image{
          url,
          alternativeText
        }
      },
      servicesTitle,
      services{
        title,
        text
      },
      innerPageObject{
        title1,
        title2,
        text,
        image{
          url,
          alternativeText
        }
      },
      partners{
        name,
        image{
          url,
          alternativeText
        }
      },
      testimonialsTitle,
      testimonialsObject{
        mainText,
        name,
        position,
        organization
      },
      statistic{
        value,
        title,
        isPercentage
      },
      callToActionText,
      callToActionLink
    }
  }`; 

export default function InnerPage(props) {
    const [content, setContent] = useState(null);
    const {data} = useQuery(CONTENT_QUERY, {
        variables: {
            url: props.url
        }
    });

    useEffect(() => {
        if(data){
            setContent(data.pages[0]);
        }
    },[data]);

    if (content) {
        return(
        <div className="innerpage">
            {content.innerPageLandingObject.map(item => 
                <div className="innerpage_section_1">
                    <div className="textbox">
                        <h2>{item.title}</h2>
                        <p>{item.text}</p>
                    </div>
                    <div className="imagebox">
                        <img src={CONSTANTS.baseUrl + item.image.url} 
                        alt={item.image.alternativeText} 
                        onLoad={(e) => {
                            e.target.style.opacity = 1;
                        }}/>
                    </div>
                </div> 
            )}
            
            {content.services.length?
                <div className="innerpage_section_2">
                    <h2>{content.servicesTitle}</h2>
                    {content.services.map((item) => {
                        if(item.text) return <CustomAccordion title={item.title} text={item.text}/>
                        else return <p>{item.title}</p>
                    })}               
                </div> : null
            }
            
            <div className="innerpage_section_3">
                {content.innerPageObject.map((item) => 
                    <div className="innerpage_info_item">
                        <div className="textbox">
                            <h3>{item.title1}</h3>
                            <p className="title">{item.title2}</p>
                            <p>{item.text}</p>
                        </div>
                        <div className="imagebox">
                            <img src={CONSTANTS.baseUrl + item.image.url} 
                            alt={item.image.alternativeText} 
                            onLoad={(e) => {
                            e.target.style.opacity = 1;
                        }}/>
                        </div>
                    </div>
                )}
            </div>
            {content.partners.length? 
                <div className="innerpage_section_4">
                    {content.partners.map((item, index, arr) => 
                        <Partner title={item.name} 
                        img={item.image} 
                        count={arr.length} />
                    )}                
                </div> : null
            }

            {content.testimonialsObject.length? 
                <Testimonials title={content.testimonialsTitle} data={content.testimonialsObject}/> : null
            }
            
            
            {content.statistic.length? 
                <div className="innerpage_section_6">
                    {
                        content.statistic.map((item) => <StatCount {...item}/>)
                    }
                </div> : null
            }
            <div className="innerpage_section_7">
                <h2>{content.callToActionText} <Link to="/contact">{content.callToActionLink}</Link></h2>
            </div>            
        </div>
        )
    }else return <TransitionPage />
}