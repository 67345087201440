import React from 'react';
import './Home.css';
import {Link} from 'react-router-dom'
import ArrowIcon from '../../Assets/Icons/arrow-right.svg';
import {ReactComponent as ArrowRightSvg} from '../../Assets/Icons/arrow-right.svg';
import {useQuery, gql} from '@apollo/client';
import TransitionPage from '../UI/TransitionPage/TransitionPage';
import CONSTANTS from '../../Constants';

const HomeQuery = gql`
query HomeQuery{
    homePage{
      landingText,
      landingImage{
        url,
        alternativeText
      },
      homeAbout{
        title,
        text,
        linkText
      },
      homeObject{
        title1,
        title2,
        text,
        url,
        linkText,
        image{
          url,
          alternativeText
        }
      },
      homeContact{
        title,
        text,
        linkText,
        image{
            url,
            alternativeText
          }
      }
    }
  }`;

export default function Home(){
    const {data} = useQuery(HomeQuery);
    console.log(data);

    if (data && data.homePage){
        return (
            <div className="home">
                <div className="home_top">
                    <h1>{data.homePage.landingText}</h1>
                    {data.homePage.landingImage&&
                        <img src={CONSTANTS.baseUrl + data.homePage.landingImage.url} alt={data.homePage.landingImage.alternativeText}/>
                    }
                </div>
                
                {data.homePage.homeAbout?
                    <div className="home_section_1">
                        <p className="medium_text">ABOUT US</p>
                        <p className="large_text">{data.homePage.homeAbout.title}</p>
                        <p className="small_text">{data.homePage.homeAbout.text}</p>
                        <Link to="/about">{data.homePage.homeAbout.linkText} <img src={ArrowIcon} alt="arrow pointing right"/></Link>
                    </div>: null
                }

                {data.homePage.homeObject && data.homePage.homeObject.map((item, index) => {
                    if(index % 2) {
                        return(
                            <div className="home_info_item_1">
                                <div className="textbox">
                                    <h2>{item.title1}</h2>
                                    <p className="title">{item.title2}</p>
                                    <p>{item.text}</p>
                                    <Link to={item.url}>{item.linkText} <ArrowRightSvg /></Link> 
                                </div>
                                <div className="imagebox">
                                    <img src={CONSTANTS.baseUrl + item.image.url} alt={item.alternativeText} onLoad={(e) => {
                                        e.target.style.opacity = 1;
                                    }}/>
                                </div>
                            </div>
                        ) 
                    }else {
                        return (
                            <div className="home_info_item_2">
                                <div className="textbox">
                                    <h2>{item.title1}</h2>
                                    <p className="title">{item.title2}</p>
                                    <p>{item.text}</p>
                                    <Link to={item.url}>{item.linkText} <ArrowRightSvg /></Link> 
                                </div>
                                <div className="imagebox">
                                    <img src={CONSTANTS.baseUrl + item.image.url} alt={item.alternativeText} onLoad={(e) => {
                                        e.target.style.opacity = 1;
                                    }}/>
                                </div>
                            </div>
                        )
                    }
                })}                
    
                {data.homePage.homeContact?
                    <div className="home_section_2">
                        <div className="textbox">
                            <h2>{data.homePage.homeContact.title}</h2>
                            <p>{data.homePage.homeContact.text}</p>
                            <Link to="/contact">{data.homePage.homeContact.linkText} <img src={ArrowIcon} alt="arrow pointing right"/></Link>
                        </div>
                        <div className="imagebox">
                            {data.homePage.homeContact.image&&
                                <img src={CONSTANTS.baseUrl + data.homePage.homeContact.image.url} alt={data.homePage.homeContact.image.alternativeText}/>
                            }
                        </div>
                    </div> : null
                }
                
            </div>
        )
    }else return <TransitionPage />
}