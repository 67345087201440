import React from 'react';
import './Footer.css';
import {Link} from 'react-router-dom'
import FullLogo from '../../Assets/drlogo.png';
import Facebook from '../../Assets/Icons/facebook.svg';
import Instagram from '../../Assets/Icons/instagram.svg';
import Twitter from '../../Assets/Icons/twitter.svg';
import LinkedIn from '../../Assets/Icons/linkedin.svg';

export default function Footer(props){

    return(
        <div className="footer">
            <div className="footer_desktop">
                <div className="footer_section_1">
                    <img src={FullLogo} alt="Design Reality Logo" className="logo"/>
                    {/* <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="Instagram"/>
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="Facebook"/>    
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                        <img src={Twitter} alt="Twitter"/>
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
                        <img src={LinkedIn} alt="Linkedin"/>
                    </a>                 */}
                </div>

                <div className="footer_section_2">
                    <div className="row">
                        <Link to="/about">About</Link>
                        {props.pages.map(item => 
                            <Link to={item.url}>{item.name}</Link>    
                        )}
                        <Link to="/contact">Contact</Link>
                    </div>
                    <div className="row">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                    </div>
                </div>

                <div className="footer_section_3">
                    <p>mudar@designrealitytech.com</p>
                    <p>© Design Reality Technology Services. All rights reserved.</p>
                </div>
            </div>
            <div className="footer_mobile">
                <div className="footer_section_1">
                    {/* <div className="column_1"> */}
                    <img src={FullLogo} alt="Design Reality Logo" className="logo"/>                 
                    {/* </div> */}
                    {/* <div className="column_2">
                        <Link to="/about">About</Link>
                        {props.pages.map(item => 
                            <Link to={item.url}>{item.name}</Link>    
                        )}
                        <Link to="/contact">Contact</Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                    </div> */}
                </div>
                <div className="footer_section_2">
                    <div className="row">
                        <Link to="/about">About</Link>
                        {props.pages.map(item => 
                            <Link to={item.url}>{item.name}</Link>    
                        )}
                        <Link to="/contact">Contact</Link>
                        
                    </div>
                    <div className="row">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                    </div>
                    <div className="row">
                        {/* <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                            <img src={Instagram} alt="Instagram"/>
                        </a>
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                            <img src={Facebook} alt="Facebook"/>    
                        </a>
                        <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                            <img src={Twitter} alt="Twitter"/>
                        </a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
                            <img src={LinkedIn} alt="Linkedin"/>
                        </a>  */}
                    </div>
                    <p>mudar@designrealitytech.com</p>       
                    <p>© Design Reality Technology Services. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}