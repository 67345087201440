import React, {useState, useEffect, useRef} from 'react';
import './About.css';
import {Link} from 'react-router-dom';
import {useQuery, gql} from '@apollo/client';
import {useTransition, a} from 'react-spring';
import {ReactComponent as Arrow} from '../../Assets/Icons/solid-arrow.svg';
import TransitionPage from '../UI/TransitionPage/TransitionPage';
import CONSTANTS from '../../Constants';

const CONTENT_QUERY = gql`
    query AboutPageQuery{
        aboutPage{
            landingText,
            landingImage{
                url,
                alternativeText
            },
            aboutObject{
                title,
                text,
                image{
                url,
                alternativeText
                }
            },
            mission{
                title,
                text
            },
            whatWeDo{
                title,
                textComponent{
                text
                }
            },
            teamTitle,
            team{
                image{
                  url,
                	alternativeText
                },
              	name,
              	title,
              	description{
                  	text
                }              	
            }
        },
        pages{
            url,
            aboutPageLink{
                image{
                    url,
                    alternativeText
                },
                linkText
            }
        }
    }`;

// Function that returns previous testimonial index
const usePreviousIndex = index => {
    const ref = useRef(0);
    useEffect(() => {
        ref.current = index;
    });
    return ref.current;
};

export default function About() {
    const [ready, setReady] = useState(true); //whether component is ready for animation, true when previous animation is at rest
    const [teamItemNumber, setTeamItemNumber] = useState(0);
    const prevTeamIndex = usePreviousIndex(teamItemNumber);

    const transitions  = useTransition(teamItemNumber, {
        from: () => {
            if(teamItemNumber > prevTeamIndex){
                return {opacity: 0, transform: 'translateX(100%)'}
            }else return {opacity: 0, transform: 'translateX(-100%)'}
        },
        enter: {opacity: 1, transform: 'translateX(0%)'},
        leave: () => {
            if(teamItemNumber > prevTeamIndex){
                return {opacity: 0, transform: 'translateX(-60%)'}
            }else return {opacity: 0, transform: 'translateX(60%)'}
        },
        onRest: () => {
            if(!ready){
                setReady(true)
            }
        },
    });


    const {data} = useQuery(CONTENT_QUERY);
    console.log(data);
    if(data && data.aboutPage){
        return(
            <div className="about">
                <div className="about_section_1">
                    <h3>{data.aboutPage.landingText}</h3>
                    {data.aboutPage.landingImage && <img src={CONSTANTS.baseUrl + data.aboutPage.landingImage.url}
                     alt={data.aboutPage.landingImage.alternativeText} onLoad={(e) => {
                            e.target.style.opacity = 1;
                        }}/>}
                </div>
                {data.aboutPage.mission? 
                    <div className="about_section_3">
                        {data.aboutPage.mission.map(item => 
                            <>
                                <h2>{item.title}</h2>
                                <p>{item.text}</p>
                            </>
                        )}
                    </div> : null
                }
                {data.aboutPage.aboutObject && data.aboutPage.aboutObject.map((item) => 
                    <div className="about_section_2">
                        <div className="textbox">
                            <h2>{item.title}</h2>
                            <p>{item.text}</p>
                        </div>
                        <div className="imagebox">
                            <img src={CONSTANTS.baseUrl + item.image.url} alt={item.image.alternativeText} onLoad={(e) => {
                                e.target.style.opacity = 1;
                            }}/>
                        </div>                
                    </div>
                )}

                {data.aboutPage.whatWeDo? 
                    <div className="about_section_4">
                        <h2>{data.aboutPage.whatWeDo.title}</h2>
                        {data.aboutPage.whatWeDo.textComponent.map((item) => 
                            <p>{item.text}</p>
                        )}                    
                    </div> : null
                }
                
                {data.pages?
                    <div className="about_section_5">
                        {data.pages.map( item => {
                            return <Link to={item.url}>
                                <img src={CONSTANTS.baseUrl + item.aboutPageLink.image.url} alt={item.aboutPageLink.image.alternativeText} onLoad={(e) => {
                                    e.target.style.opacity = 1;
                                }}/>
                                <p>{item.aboutPageLink.linkText}</p>
                            </Link>
                        })}
                    </div> : null
                }

                {data.aboutPage.team.length? <div className="about_section_6">
                    <h2>{data.aboutPage.teamTitle}</h2>
                    <div className="main">
                        {transitions((style, item) => 
                            <a.div className="item" style={{...style}}>
                                <img src={CONSTANTS.baseUrl + data.aboutPage.team[item].image.url} alt="" />
                                <div className="content">
                                    <p className="name">{data.aboutPage.team[item].name}</p>
                                    <p className="title">{data.aboutPage.team[item].title}</p>
                                    <div className="descriptions">
                                        {data.aboutPage.team[item].description.map(desc =>
                                            <p>{desc.text}</p>    
                                        )}
                                    </div>
                                </div>
                            </a.div>
                        )}
                    </div>
                    <div className="controls">
                        <div className="line" />
                        <button 
                            disabled={teamItemNumber === 0}
                            onClick={() => {
                                if(ready){
                                    setReady(false);
                                    setTeamItemNumber(num => num - 1)
                                }
                            }}
                        ><Arrow /></button>
                        <button 
                            disabled={teamItemNumber === (data.aboutPage.team.length - 1)}
                            onClick={() => {
                                if(ready){
                                    setReady(false);
                                    setTeamItemNumber(num => num + 1)
                                }
                            }}
                        ><Arrow /></button>
                    </div>
                </div> : null}
    
            </div>
        )
    }else return <TransitionPage />
}